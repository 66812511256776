import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
// import { useTranslation } from 'gatsby-plugin-react-i18next';
// import { IGatsbyImageData, StaticImage, getImage } from 'gatsby-plugin-image';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Form } from './components/Form';
// import { SocialIcon } from './components/SocialIcon';
import colors from 'constants/styles/colors';
import media from 'constants/styles/media';

const SFooter = styled.footer`
    height: 448px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: ${media.tablet}) {
        height: 750px;
    }
`;

const STopWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${media.tablet}) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }
`;

const STextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-right: 72px;

    @media (max-width: ${media.tablet}) {
        margin-right: 0;
        margin-bottom: 60px;
    }
`;

// const SCompanyInfoWrapper = styled.div`
//     margin-bottom: 32px;
// `;

// const SParagraph = styled.p`
//     font-size: 16px;
//     font-weight: 400;
//     letter-spacing: 0px;
//     color: ${colors.text};
// `;

// const SSocialWrapper = styled.div`
//     height: 50px;
//     width: 152px;
//     display: flex;
//     aling-items: center;
//     justify-content: space-between;
// `;

const SBottomWrapper = styled.div`
    width: calc(100% - 274px);
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @media (max-width: ${media.tablet}) {
        height: 100px;
        width: calc(100% - 40px);
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: space-around;
    }
`;

const RODOWrapper = styled.div`
    width: 310px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${media.tablet}) {
        margin-bottom: 24px;
    }
`;

const RODOLink = styled.p`
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    color: ${colors.text};
`;

// const alts: string[] = ['facebook icon', 'linked in icon', 'twitter icon'];

export const Footer = () => {
    // const { t } = useTranslation();

    // const { socials } = useStaticQuery(graphql`
    //     query socialIcons {
    //         socials: allFile(filter: { relativeDirectory: { eq: "socials" } }) {
    //             nodes {
    //                 id
    //                 childImageSharp {
    //                     gatsbyImageData(
    //                         placeholder: BLURRED
    //                         formats: [AUTO, WEBP, AVIF]
    //                         layout: FULL_WIDTH
    //                     )
    //                 }
    //             }
    //         }
    //     }
    // `);

    return (
        <SFooter id="footer">
            <STopWrapper>
                <STextWrapper>
                    <StaticImage
                        src="../../images/turo-logo.png"
                        alt="TURO logo"
                        style={{
                            height: 30,
                            width: 117,
                            marginBottom: 23,
                            objectFit: 'contain',
                        }}
                    />
                    {/* <SCompanyInfoWrapper>
                        <SParagraph>{t('footer.company')}</SParagraph>
                        <SParagraph>{t('footer.adress')}</SParagraph>
                        <SParagraph>{t('footer.adress2')}</SParagraph>
                        <SParagraph>{t('footer.phone')}</SParagraph>
                    </SCompanyInfoWrapper>
                    <SSocialWrapper>
                        {socials &&
                            socials.nodes.map((social: IGatsbyImageData, i: number) => {
                                const image = getImage(social);
                                return image && <SocialIcon key={i} image={image} alt={alts[i]} />;
                            })}
                    </SSocialWrapper> */}
                </STextWrapper>
                <Form />
            </STopWrapper>
            <SBottomWrapper>
                <RODOWrapper>
                    <RODOLink>Polityka prywatności</RODOLink>
                    <RODOLink>Warunki użytkowania</RODOLink>
                </RODOWrapper>
                <RODOLink>Copyright © 2021 TURO. All rights reserved.</RODOLink>
            </SBottomWrapper>
        </SFooter>
    );
};
