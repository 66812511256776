import colors from 'constants/styles/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
    error: boolean;
}

const SWrapper = styled.div<Props>`
    width: 100%;
    height: 48px;
    background-color: ${(p) => (p.error ? '#cc3300' : '#99cc33')};
    opacity: 0.8;
    padding: 16px 20px;
    border-radius: 8px;
    position: absolute;
    top: -64px;
`;

const SParagraph = styled.p`
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    color: ${colors.white};
`;

export const Alert = ({ error }: Props) => {
    const { t } = useTranslation();

    return (
        <SWrapper error={error}>
            <SParagraph>{error ? t('alert.error') : t('alert.sent')}</SParagraph>
        </SWrapper>
    );
};
