const colors = {
    white: '#fff',
    text: '#96969D',
    textBold: '#363639',
    orange: '#EEBF4F',
    cardBackground: '#0000001A',
    border: '#ebebeb',
    icon: '#96969D',
};

export default colors;
