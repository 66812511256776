export const navItems = [
    {
        path: '/#intro',
        text: 'navigation.nav-items.intro',
    },
    {
        path: '/#what-do-you-get',
        text: 'navigation.nav-items.what-do-you-get',
    },
    {
        path: '/#about',
        text: 'navigation.nav-items.about',
    },
    {
        path: '/#about-us',
        text: 'navigation.nav-items.about-us',
    },
    {
        path: '/#footer',
        text: 'navigation.nav-items.footer',
    },
];
