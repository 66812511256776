import media from 'constants/styles/media';
import React from 'react';
import colors from 'src/constants/styles/colors';
import styled from 'styled-components';

interface StyleProps {
    variant?: 'filled' | 'outlined' | 'plain';
}

const SButton = styled.button<StyleProps>`
    border-color: none;
    background-color: none;
    color: ${(p) =>
        p.variant === 'plain' ? '#000' : p.variant === 'outlined' ? colors.orange : colors.white};
    padding: 10px 30px;
    border-radius: 25px;
    border: 2px solid ${(p) => (p.variant === 'plain' ? 'transparent' : colors.orange)};
    background-color: ${(p) => (p.variant === 'filled' ? colors.orange : 'transparent')};
    cursor: pointer;

    @media (maqx-width: ${media.tablet}) {
        padding: 15px 50px;
    }
`;

const SParagraph = styled.p`
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
`;

interface Props extends StyleProps {
    title: string;
}

export const Button = ({ title, variant = 'filled' }: Props) => (
    <SButton variant={variant}>
        <SParagraph>{title}</SParagraph>
    </SButton>
);
