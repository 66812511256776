import React from 'react';
import styled from 'styled-components';

import { Link } from 'components/link';
import { Button } from 'components/button';
import colors from 'src/constants/styles/colors';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import media from 'constants/styles/media';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { APP_URL } from 'constants/index';
import { navItems } from '../navItems';

const SNav = styled.nav`
    display: flex;
    align-items: center;

    @media (max-width: ${media.desktop}) {
        display: none;
    }
`;

const SList = styled.ul`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
`;

const SListItem = styled(AnchorLink)`
    cursor: pointer;
    margin-right: 48px;
    color: ${colors.text};
    text-decoration: none;
    display: inline-block;
    position: relative;
    font-size: 14px;

    :hover {
        color: ${colors.textBold};
        text-shadow: 0 0 0.01px ${colors.textBold};
    }

    ::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 6px;
        background: ${colors.orange};
        bottom: -6px;
        border-radius: 6px;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;
    }

    :hover::before {
        visibility: visible;
        opacity: 1;
    }
`;

const SMlWrapper = styled.div`
    margin-left: 48px;
`;

export const Nav = () => {
    const { t } = useTranslation();

    return (
        <SNav>
            <SList>
                {navItems.map(({ path, text }) => (
                    <SListItem to={path} key={path}>
                        {t(text)}
                    </SListItem>
                ))}
            </SList>
            <Link href={`${APP_URL}/login`} title={t('navigation.link')} variant="plain" />
            <SMlWrapper>
                <AnchorLink to="/#footer">
                    <Button title={t('navigation.button')} variant="outlined" />
                </AnchorLink>
            </SMlWrapper>
        </SNav>
    );
};
