import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useMemo,
    useState,
} from 'react';

interface FooterStore {
    textAreaText: string;
    setTextAreaText: Dispatch<SetStateAction<string>>;
}

export const FooterContext = createContext<FooterStore | null>(null);
export const useFooterContext = () => useContext(FooterContext) as FooterStore;

interface Props {
    children: ReactNode;
}

export const FooterFormContext = ({ children }: Props) => {
    const [textAreaText, setTextAreaText] = useState('');
    const footerStore = useMemo<FooterStore>(
        () => ({
            textAreaText,
            setTextAreaText,
        }),
        [textAreaText, setTextAreaText],
    );

    return <FooterContext.Provider value={footerStore}>{children}</FooterContext.Provider>;
};
