import React, { useState } from 'react';
import styled from 'styled-components';

import { Nav } from './components/Nav';
import { StaticImage } from 'gatsby-plugin-image';
import { Burger } from './components/Burger';
import media from 'constants/styles/media';
import colors from 'constants/styles/colors';
import { MobileNav } from './components/MobileNav';
import { zIndex } from 'constants/styles/z-index';

const SHeader = styled.header`
    top: 0;
    left: 0;
    height: 100px;
    width: 100vw;
    margin: 0 auto;
    z-index: ${zIndex.navigationHeader};
    position: fixed;
    background: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${media.tablet}) {
        justify-content: flex-start;
    }
`;

const SWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 80px;
    width: 100%;
    max-width: 1200px;

    @media (max-width: ${media.tablet}) {
        padding: 25px 16px;
    }
`;

interface Props {
    siteTitle: string;
}

export const Navigation = ({ siteTitle }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMobileNavOpen = () => setIsOpen(!isOpen);

    return (
        <SHeader>
            <SWrapper>
                <StaticImage
                    src="../../images/turo-logo.png"
                    alt={siteTitle}
                    style={{
                        width: 117,
                        height: 30,
                        marginRight: 30,
                    }}
                />
                <Burger onClick={handleMobileNavOpen} isOpen={isOpen} />
                <Nav />
            </SWrapper>
            {isOpen && <MobileNav onClick={handleMobileNavOpen} />}
        </SHeader>
    );
};
