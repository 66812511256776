import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Navigation } from 'src/modules/navigation';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Footer } from 'src/modules/footer';
import { FooterFormContext } from 'utils/footer-context/index';

const SAppWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
`;

const SAppWidthLimit = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 0 80px;
    position: relative;

    @media (max-width: 768px) {
        padding: 0 19px;
    }
`;

type Props = {
    children: ReactNode;
};

export const Layout = ({ children }: Props) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <SAppWrapper>
            <SAppWidthLimit>
                <FooterFormContext>
                    <Navigation siteTitle={data.site.siteMetadata?.title || `Title`} />
                    <main>{children}</main>
                    <Footer />
                </FooterFormContext>
            </SAppWidthLimit>
        </SAppWrapper>
    );
};
