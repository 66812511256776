import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useMutation } from 'urql';

import { Alert } from 'components/alert';
import { Button } from 'components/button';
import { Input } from 'components/input';
import colors from 'constants/styles/colors';
import media from 'constants/styles/media';
import { ContactFormValues } from '../types';
import { useAlert } from '../utils';
import { useFooterContext } from 'utils/footer-context';

const SForm = styled.form`
    width: 618px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;

    @media (max-width: ${media.tablet}) {
        width: 100%;
        align-items: flex-start;
        margin-bottom: 60px;
    }
`;

const STopInputWrapper = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;

    @media (max-width: ${media.tablet}) {
        flex-direction: column;
    }
`;

const STextArea = styled.textarea`
    width: 618px;
    height: 96px;
    background: #ffffff;
    border: 1px solid #d4d1d1;
    border-radius: 8px;
    resize: none;
    padding: 16px 20px;
    text-align: left;
    margin-bottom: 13px;

    ::placeholder,
    ::-webkit-input-placeholder {
        font-size: 14px;
        font-weigth: 400;
        letter-spacing: 0px;
        color: ${colors.text};
    }

    @media (max-width: ${media.tablet}) {
        width: 100%;
        margin-top: 60px;
    }
`;

export const Form = () => {
    const { register, handleSubmit, setValue } = useForm();
    const [, update] = useMutation(SEND_CONTACT_EMAIL_MUTATION);
    const { messageStatus, successAlert, errorAlert, hideAlert } = useAlert();
    const { textAreaText } = useFooterContext();

    useEffect(() => {
        setValue('message', textAreaText);
    }, [textAreaText]);

    const onSubmit = async (values: ContactFormValues) => {
        try {
            const { data } = await update({ input: values });

            data.sendContactEmail.success ? successAlert() : errorAlert();
        } catch (err) {
            errorAlert();
        } finally {
            hideAlert(3000);
        }
    };

    return (
        <SForm onSubmit={handleSubmit(onSubmit)}>
            {messageStatus.sent && <Alert error={messageStatus.error} />}
            <STopInputWrapper>
                <Input
                    name="name"
                    label="Imię"
                    placeholder="Imię"
                    type="text"
                    register={register}
                />
                <Input
                    name="email"
                    label="E-mail"
                    placeholder="E-mail"
                    type="email"
                    register={register}
                />
            </STopInputWrapper>
            <STextArea placeholder="Wiadomość" cols={30} rows={10} {...register('message')} />
            <Button title="Wyślij" />
        </SForm>
    );
};

const SEND_CONTACT_EMAIL_MUTATION = `mutation SendContactEmailMutation($input: SendContactEmailInput!) {
    sendContactEmail(input: $input) {
        success
    }
}`;
