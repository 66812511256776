import media from 'constants/styles/media';
import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import colors from 'src/constants/styles/colors';
import styled from 'styled-components';

interface Props {
    type: string;
    name: 'name' | 'email' | 'message';
    label: string;
    placeholder: string;
    register: UseFormRegister<FieldValues>;
}

const SWrapper = styled.div`
    height: 48px;
    width: 302px;
    background: ${colors.white};
    position: relative;
    border: 1px solid #d4d1d1;
    border-radius: 8px;

    @media (max-width: ${media.tablet}) {
        margin-bottom: 14px;
        width: 100%;
    }
`;

const SLabel = styled.label`
    visibility: hidden;
    position: absolute;
`;

const SInput = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    padding: 16px 20px;
    border-radius: 8px;

    ::placeholder,
    ::-webkit-input-placeholder {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        color: ${colors.text};
    }
`;

export const Input = ({ type, name, label, placeholder, register }: Props) => {
    return (
        <SWrapper>
            <SLabel htmlFor={name}>{label}</SLabel>
            <SInput type={type} placeholder={placeholder} {...register(name)} />
        </SWrapper>
    );
};
