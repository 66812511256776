import colors from 'constants/styles/colors';
import React from 'react';
import styled from 'styled-components';

interface StyleProps {
    variant?: 'filled' | 'outlined' | 'plain';
    disabled?: boolean;
}

const SLinkButton = styled.a<StyleProps>`
    text-decoration: none;
    padding: ${(p) =>
        p.variant === 'filled' ? '10px 50px' : p.variant === 'outlined' ? '10px 30px' : '0'};
    border-radius: 25px;
    border: 2px solid ${(p) => (p.variant === 'plain' ? 'transparent' : colors.orange)};
    background-color: ${(p) => (p.variant === 'filled' ? colors.orange : 'transparent')};
    cursor: pointer;
    text-align: center;
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    box-shadow: ${(p) => (p.variant === 'filled' ? '0px 8px 18px #4D3D091F' : 'none')};
`;

const SParagraph = styled.p<StyleProps>`
    color: ${(p) => (p.variant === 'filled' ? colors.white : colors.textBold)};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
`;

interface Props extends StyleProps {
    href: string;
    title: string;
}

export const Link = ({ href, title = 'Title', variant = 'filled', disabled = false }: Props) => {
    return (
        <SLinkButton href={href} variant={variant} disabled={disabled}>
            <SParagraph variant={variant}>{title}</SParagraph>
        </SLinkButton>
    );
};
