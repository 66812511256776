import React from 'react';
import styled from 'styled-components';

import { StaticImage } from 'gatsby-plugin-image';
import media from 'constants/styles/media';
import { zIndex } from 'constants/styles/z-index';

const SWrapper = styled.div`
    position: absolute;
    top: 30px;
    right: 16px;
    z-index: ${zIndex.burger};
    margin-right: 40px;
    cursor: pointer;

    @media (min-width: ${media.desktop}) {
        display: none;
    }

    @media (max-width: ${media.tablet}) {
        margin-right: 0;
    } ;
`;

interface Props {
    onClick: () => void;
    isOpen: boolean;
}

export const Burger = ({ onClick, isOpen }: Props) => {
    return (
        <SWrapper onClick={onClick}>
            {isOpen ? (
                <StaticImage
                    src={`../../../images/close.png`}
                    alt="Mobile menu close icon"
                    style={{
                        width: 30,
                        height: 22,
                    }}
                />
            ) : (
                <StaticImage
                    src={`../../../images/burger.png`}
                    alt="Mobile menu open icon"
                    style={{
                        width: 30,
                        height: 22,
                        opacity: 0.5,
                    }}
                />
            )}
        </SWrapper>
    );
};
