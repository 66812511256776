import React from 'react';
import styled from 'styled-components';
import colors from 'constants/styles/colors';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'react-i18next';
import { Link } from 'components/link';
import { APP_URL } from 'constants/index';
import { Button } from 'components/button';
import { navItems } from '../navItems';
import { zIndex } from 'constants/styles/z-index';

const SWrapper = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${zIndex.navigationHeader};
    background: ${colors.white};
    display: grid;
    place-items: center;
`;

const SList = styled.ul`
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    list-style: none;
`;

const SListItem = styled(AnchorLink)`
    cursor: pointer;
    color: ${colors.text};
    text-decoration: none;
    display: inline-block;
    position: relative;
    font-size: 2rem;

    :hover {
        color: ${colors.textBold};
        font-weight: 700;
    }

    ::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 6px;
        background: ${colors.orange};
        bottom: -6px;
        border-radius: 6px;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;
    }

    :hover::before {
        visibility: visible;
        opacity: 1;
    }
`;

interface Props {
    onClick: () => void;
}

export const MobileNav = ({ onClick }: Props) => {
    const { t } = useTranslation();

    return (
        <SWrapper>
            <SList>
                {navItems.map(({ path, text }) => (
                    <SListItem onAnchorLinkClick={onClick} to={path} key={path}>
                        {t(text)}
                    </SListItem>
                ))}
                <Link href={`${APP_URL}/login`} title={t('navigation.link')} variant="plain" />
                <AnchorLink to="/#footer" onAnchorLinkClick={onClick}>
                    <Button title={t('navigation.button')} variant="outlined" />
                </AnchorLink>
            </SList>
        </SWrapper>
    );
};
