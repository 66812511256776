import { useState } from 'react';

export const useAlert = () => {
    const [messageStatus, setMessageStatus] = useState({
        sent: false,
        error: false,
    });

    const successAlert = () => {
        setMessageStatus({
            sent: true,
            error: false,
        });
    };

    const errorAlert = () => {
        setMessageStatus({
            sent: true,
            error: true,
        });
    };

    const hideAlert = (timeoutMs: number) => {
        setTimeout(() => {
            setMessageStatus({
                sent: false,
                error: false,
            });
        }, timeoutMs);
    };

    return { messageStatus, successAlert, errorAlert, hideAlert };
};
